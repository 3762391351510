import API from './axiosClient';
import config from './../config';

const URL =  config.apiURL;

function getForms() {
    return API.get(URL + '/forms');
}

function getAvailableFieldsById(id, data) {
    return API.get(URL +`/forms/${id}/avaiable-fields`, data);
}

function addForms(data) {
    return API.post(URL + '/forms', data);
}

function getForm(id, data) {
    return API.get(URL +`/forms/${id}`, data);
}

function putForms(id, data) {
    return API.put(URL +`/forms/${id}`, data);
}


function deleteForms(id) {
    return API.delete(URL +`/forms/${id}`);
}

export default {
    getForms,
    addForms,
    putForms,
    deleteForms,
    getForm,
    getAvailableFieldsById
};